/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import ModalComponent from "../../components/Modal/Modal";
import PaginateComponent from "../../components/Paginate";
import Search from "../../components/Search";
import FormDeliveryUser from "../../forms/FormDeliveryUser";
import FormMassiveLoadDeliveryUser from "../../forms/FormMassiveLoadDeliveryUser";
import { _pushToastMessage } from "../../helpers";
import { createCsvSimple } from "../../helpers/csv";
import { _handleError } from "../../helpers/errors";
import { loading_off, loading_on } from "../../redux/actions/loader";
import DeliveryUserService from "../../services/delivery_user.service";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Filters from "./Filters";
import Header from "./Header";

import { TableComponent } from "./TableComponent";

const DeliveryUsers = () => {
  const [data, setData] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [state, setState] = useState<any>({});
  const dispatch: any = useDispatch();
  const [filters, setFilters] = useState<any>(null);
  const DeliveryUser = new DeliveryUserService();

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  useEffect(() => {
    setChecked(false);
    setIndeterminate(false);
    if (checkedKeys.length === data.data?.length) {
      setChecked(true);
    } else if (checkedKeys.length === 0) {
      setChecked(false);
    } else if (
      checkedKeys.length > 0 &&
      checkedKeys.length < data.data?.length
    ) {
      setIndeterminate(true);
    }
  }, [checkedKeys, data]);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async (search: any = null) => {
    dispatch(loading_on());

    try {
      let response = null;
      if (search !== null) {
        response = await DeliveryUser.list({ name: search, email: search });
      } else {
        response = await DeliveryUser.list();
      }

      setData(response.data);

      setState({
        ...state,
        createDeliveryUserModal: false,
        searchModal: false,
        massive_load: false,
        filters: false,
      });

      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  const _handleSelectChangeGlobal = (payload: any) => {
    switch (payload.option) {
      case 1:
        _exportToCsv();
        break;
      case 2:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar los usuarios seleccionados?",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteSelected(checkedKeys),
        });
        break;
      case 3:
        _toggleMassiveLoad();
        break;
      default:
        console.log(payload);
    }
  };

  const _handleCheckAll = (value: any, checked: any) => {
    const keys: any = checked ? data.data?.map((item: any) => item.id) : [];
    setCheckedKeys([...keys]);
  };

  const _handleCheck = (value: any, checked: any) => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item: any) => item !== value);
    setCheckedKeys([...keys]);
  };

  const _toggleCreateDeliveryUserModal = () =>
    setState({
      ...state,
      createDeliveryUserModal: !state.createDeliveryUserModal,
    });

  const _toggleFilters = () =>
    setState({
      ...state,
      filters: !state.filters,
    });

  const _toggleSearchModal = () =>
    setState({ ...state, searchModal: !state.searchModal });

  const _toggleMassiveLoad = () =>
    setState({
      ...state,
      massive_load: !state.massive_load,
    });

  const _deleteSelected = async (list: any) => {
    dispatch(loading_on());

    try {
      await DeliveryUser.deleteList(list);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Usuarios eliminadas con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      setCheckedKeys([]);
      _getData();
    } catch (e: any) {
      _handleError(e, e.message);
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

  const _handleFilters = (data: any, params: any = null) => {
    setData(data);
    setFilters(params);
    _toggleFilters();
  };

  const _clearFilters = () => {
    setFilters(null);
    _getData();
  };

  const _exportToCsv = async () => {
    dispatch(loading_on);

    try {
      const responseGetCsvData: any = await DeliveryUser.filterCsvData(
        filters || {}
      );
      const dataCsv: any = responseGetCsvData.data;
      if (dataCsv.length === 0) {
        throw new Error(
          "Esta búsqueda no arrojó ningún resultado. Intente cambiar los filtros para mejorar la búsqueda."
        );
      }

      const headers: any = [
        "Nombre",
        "Email",
        "Telefono",
        "Dirección",
        "Departamento",
        "Empresa",
      ];

      let csv: any = headers.join(",") + "\r\n";

      for (const item of dataCsv) {
        item.address = item.address?.replace(/,/g, " -");
        item.address = item.address?.replace(/\n/g, "");
        csv += `${item.name},${item.email},${item.phone},${item.address},${item.apartment},${item.company?.name}`;
        csv += "\r\n";
      }
      createCsvSimple(csv, "delivery_users");

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Datos exportados con éxito...",
      });
      dispatch(loading_off);
    } catch (e: any) {
      _pushToastMessage({
        type: "error",
        header: "Error",
        text: e.message,
      });
      dispatch(loading_off);
    }
  };

  return (
    <DefaultTemplate>
      <ModalComponent
        open={state.createDeliveryUserModal}
        size="md"
        title="Agregar Delivery User"
        handleClose={_toggleCreateDeliveryUserModal}
      >
        <FormDeliveryUser type="create" handleConfirm={() => _getData()} />
      </ModalComponent>

      <ModalComponent
        open={state.filters}
        size="md"
        title="Filtros"
        handleClose={_toggleFilters}
      >
        <Filters
          handleCallback={_handleFilters}
          handleClose={_toggleFilters}
          filters={filters}
          clearFilters={_clearFilters}
        />
      </ModalComponent>

      <ModalComponent
        open={state.massive_load}
        size="lg"
        title="Carga Masiva"
        handleClose={_toggleMassiveLoad}
      >
        <FormMassiveLoadDeliveryUser
          handleCallback={_getData}
          handleClose={_toggleMassiveLoad}
        />
      </ModalComponent>

      <ModalComponent
        open={state.searchModal}
        size="md"
        title="Busca rapida"
        handleClose={_toggleSearchModal}
      >
        <Search
          handleSearch={(value: any) => _getData(value)}
          placeholder="Busca por el nombre del usuario, email o numero departamento"
        />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar usuarios"
        handleClose={() => confirmation.handleClose()}
        type="warning"
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <div className="row mx-0 px-4 py-5 with-header">
        <div className="default-template-box">
          <div className="row">
            <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
              <Header
                handleSelectChange={_handleSelectChangeGlobal}
                handleSearchModal={_toggleSearchModal}
                handleCreateModal={_toggleCreateDeliveryUserModal}
                handleFilters={_toggleFilters}
                handleMassiveLoad={_toggleMassiveLoad}
                filters={filters}
              />
            </div>
            <div className="col-12 py-5">
              <TableComponent
                indeterminate={indeterminate}
                checked={checked}
                data={data}
                checkedKeys={checkedKeys}
                handleCheckAll={_handleCheckAll}
                handleCheck={_handleCheck}
                getData={_getData}
              />
            </div>
            <div className="col-12 text-center mb-5">
              <PaginateComponent data={data} setData={setData} />
            </div>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default DeliveryUsers;
