/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Input, MaskedInput } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import Logo from "../../components/Logo";
import "./style.scss";
import { useDispatch } from "react-redux";
import { set_loader } from "../../redux/actions/loader";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import ErrorValidation from "../../components/ErrorValidation";
import { PendingDeliveryUserSchema } from "../../validations/delivery.users.validations";
import { phoneMask } from "../../helpers/masks";
import CompanyService from "../../services/company.service";
import { useParams } from "react-router-dom";

const RegisterQrUser = () => {
  const [state, setState] = useState<any>({
    name: "",
    email: "",
    phone: "",
    rut: "111111111",
    apartment: "",
    address: "",
    token: "",
    validations: {},
    isDisabled: false,
  });

  const [showMessage, setShowMessage] = useState(false);

  const dispatch: any = useDispatch();
  const Company = new CompanyService();
  const params = useParams();

  useEffect(() => {
    _getCompanyByToken();
  }, []);

  const _getCompanyByToken = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));
      const response = await Company.getCompanyByQrToken({
        token: params.token,
      });
      setState({
        ...state,
        token: params.token,
        address: response.data.address,
      });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      _handleError(e, "No fue posible obtener la información de la empresa");
      dispatch(set_loader({ is_loading: false }));
      setState({
        ...state,
        isDisabled: true,
      });
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  const _handleSubmit = async (event: any) => {
    event.preventDefault();
    let validations: any = await validate(PendingDeliveryUserSchema, state);

    if (state.phone.indexOf("_") !== -1) {
      validations = validations || {};
      validations.phone = ["Numero de telefóno inválido"];
    }

    if (validations !== false) {
      setState({ ...state, validations });
      return;
    }
    setState({ ...state, validations: {} });

    dispatch(set_loader({ is_loading: true }));

    try {
      const responseRegisterUser = await Company.createQrRegisterUser(state);
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: responseRegisterUser.data.message,
      });
      setShowMessage(true);
      setState({
        ...state,
        name: "",
        email: "",
        phone: "",
        apartment: "",
        rut: "111111111",
        validations: {},
      });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, "No fue posible iniciar sesion");
    }
  };

  if (showMessage) {
    return (
      <div className="container-fluid sign-in p-0">
        <div className="content d-flex justify-content-center align-items-center p-3">
          <div
            className="sign-in-box shadow text-center"
            style={{ fontSize: "18px" }}
          >
            <div>Registro completado exitosamente</div>
            <div className="mt-4">
              <Button
                type="submit"
                appearance="primary"
                onClick={() => setShowMessage(false)}
              >
                {" "}
                Registrar Otro{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid sign-in p-0">
      <div className="content d-flex justify-content-center align-items-center p-3">
        <div className="sign-in-box shadow">
          <form>
            <div className="row">
              <div className="col-12 text-center mb-4">
                <Logo style={{ width: "100px" }} />
              </div>
              <div className="w-100 text-center size-15 mb-3">Inscripción</div>
              <div className="col-12 mb-3">
                Nombre
                <Input
                  value={state.name}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "name", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                Email
                <Input
                  value={state.email}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "email", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="email"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                Teléfono
                <MaskedInput
                  mask={phoneMask}
                  value={state.phone}
                  guide={true}
                  showMask={true}
                  keepCharPositions={true}
                  placeholder={""}
                  placeholderChar={"_"}
                  onChange={(value: any) =>
                    _handleOnChange({ target: { name: "phone", value } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="phone"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                Número Departamento/Casa
                <Input
                  value={state.apartment}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "apartment", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="apartment"
                  />
                </div>
              </div>

              <div className="col-12 text-center">
                <Button
                  type="submit"
                  appearance="primary"
                  onClick={_handleSubmit}
                  disabled={state.isDisabled}
                >
                  {" "}
                  Registrar{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterQrUser;
