import EditIcon from "@rsuite/icons/Edit";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import TrashIcon from "@rsuite/icons/Trash";
import UserInfoIcon from "@rsuite/icons/UserInfo";
import { ReactComponent as Locker } from "../../assets/img/svg/locker.svg";
import QrcodeIcon from '@rsuite/icons/Qrcode'; 
import store from "../../redux/store";
export const _actionMenuSpeaker = (data: any, _handleOnSelect: any) => {
  const { session } = store.getState();
  const { profile } = session;
  return {
    menu: [
      {
        label: "Detalles",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 2,
      },
      {
        label: "Editar",
        Icon: EditIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Terminales",
        Icon: Locker,
        show:
          profile?.roles?.find((role: any) => role?.name === "super-admin") &&
          (data?.company_type?.name === "sameday" ||
            data?.company_type?.name === "ecommerce"),
        eventKey: 4,
      },
      {
        label: "Eliminar",
        Icon: TrashIcon,
        show: true,
        eventKey: 3,
      },
      {
        label: "Configuraciones prestamos",
        Icon: Locker,
        show:
          profile?.roles?.find((role: any) => role?.name === "super-admin") &&
          (data?.company_type?.name === "lending"),
        eventKey: 5,
      },
      {
        label: "Qr Inscripción",
        Icon: QrcodeIcon,
        show:
          profile?.roles?.find((role: any) => role?.name === "super-admin") &&
          (data?.company_type?.name === "residencial"),
        eventKey: 6,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey, id: data.id }),
  };
};

export const _actionMenuSpeakerAll = (_handleOnSelect: any) => {
  return {
    menu: [
      {
        label: "Exportar",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Crear Usuario",
        Icon: UserInfoIcon,
        show: true,
        eventKey: 2,
      },
      {
        label: "Eliminar seleccionados",
        Icon: TrashIcon,
        show: true,
        eventKey: 3,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey }),
  };
};
