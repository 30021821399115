/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, SelectPicker } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import {
  loading_off,
  loading_on,
  set_loader,
} from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import UploadFile from "../../components/UploadFiles";
import Papa from "papaparse";
import validate from "../../helpers/validate";
import { MassiveOrderResidentSchema } from "../../validations/massive_order.validations";
import ErrorsTable from "./ErrorsTable";
import { createCsvSimple } from "../../helpers/csv";
import DeliveryUserService from "../../services/delivery_user.service";

const FormMassiveLoadDeliveryUser = (props: any) => {
  const { handleClose, handleCallback } = props;
  const [state, setState] = useState<any>({
    companies: [],
    selectCompanies: [],
    formType: 0,
    checked: false,
    validations: {},
    company: {},
    inputs: {
      email: "",
      name: "",
      rut: "",
      phone: "",
      package_name: "",
      external_reference: "",
      terminal_id: "",
      boxes: [],
    },
  });
  const [csvData, setCsvData] = useState<any>(null);
  const [errors, setErrors] = useState([]);
  const dispatch: any = useDispatch();
  const Company = new CompanyService();
  const DeliveryUser = new DeliveryUserService();

  useEffect(() => {
    _loadCompanies();
  }, []);

  const _loadCompanies = async () => {
    dispatch(set_loader({ is_loading: true }));
    try {
      const response: any = await Company.listByUser();
      const companies: any = response.data?.filter(
        (item: any) => item.company_type?.name === "residencial"
      );
      const selectCompanies: any = companies.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setState({ ...state, selectCompanies, companies: response.data });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
      handleClose();
    }
  };

  const _handleSelectCompany = async (value: any) => {
    if (value === null) {
      Object.keys(state.inputs).forEach((key: any) => {
        state.inputs[key] = "";
      });
      setState({ ...state, formType: 0, terminalList: [] });
      return;
    }
    const company = state.companies.find((item: any) => item.id === value);
    if (company) {
      let formType = 5;
      const terminals = await Company.getTerminals(company.id);
      const selectlistTerminals: any = terminals.data?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setState({
        ...state,
        formType,
        terminals: terminals.data,
        terminalList: selectlistTerminals,
        company,
      });
    }
  };

  const _parseCsvFile = (data: any) => {
    dispatch(loading_on());
    if (data?.file?.blobFile) {
      Papa.parse(data.file.blobFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results: any) => {
          _validateCsvData(results.data);
        },
        error: () => {
          dispatch(loading_off());
        },
      });
    } else {
      setCsvData(null);
      setErrors([]);
      dispatch(loading_off());
    }
  };

  const _validateCsvData = async (data: any) => {
    const errors: any = [];
    try {
      for (const item in data) {
        data[item].terminal_id = state.inputs.terminal_id;
        data[item].company_id = state.company?.id;
        const validations: any = await validate(
          MassiveOrderResidentSchema,
          data[item]
        );
        if (validations) {
          Object.keys(validations).forEach((error: any) => {
            errors.push({
              field: error,
              error_text: validations[error][0],
              text: data[item][error],
              line: parseInt(item) + 2,
            });
          });
        }
      }
      if (errors) setErrors(errors);
      setCsvData(data);
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible validar el archivo csv.");
    }
  };

  const downloadCsvBase = () => {
    const headers: any = [
      "nombre",
      "email",
      "direccion",
      "departamento",
      "telefono",
      "rut",
      "inclusion",
    ];

    const csv: any = headers.join(",") + "\n";
    createCsvSimple(csv, "elock_csv_base_residentes.csv");
  };

  const _handleSubmit = async () => {
    if (errors && errors.length > 0) {
      _pushToastMessage({
        text: "Verifica los errores informados antes de continuar",
        type: "warning",
        header: "Aviso",
      });
      return;
    }

    if (!csvData) {
      _pushToastMessage({
        text: "Ingresa un archivo csv válido",
        type: "warning",
        header: "Aviso",
      });
      return;
    }

    if (!state.company?.id) {
      _pushToastMessage({
        text: "Selecciona empresa antes de continuar",
        type: "warning",
        header: "Aviso",
      });
      return;
    }

    try {
      dispatch(loading_on());
      csvData.map((item: any) => {
        item.name = item.nombre;
        item.address = item.direccion;
        item.apartment = item.departamento;
        item.phone = item.telefono;
        item.has_disability = (item.inclusion?.toLowerCase() === "si" || parseInt(item.inclusion) === 1) ? true : false;
        return item;
      });
      await DeliveryUser.createMassiveDeliveryUsers({ data: csvData });
      _pushToastMessage({
        text: "Datos cargados con éxito",
        type: "success",
        header: "Éxito",
      });
      handleCallback();
    } catch (e: any) {
      dispatch(loading_off());
      _pushToastMessage({
        text: "No fue posible cargar los datos informados",
        type: "warning",
        header: "Aviso",
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12">
          <span className="size-08 bold-300">Empresa</span>
          <SelectPicker
            className="w-100"
            onChange={_handleSelectCompany}
            locale={{
              emptyMessage:
                "No fue encontrado ninguna empresa asociada a este usuario",
              placeholder: "Seleccione una empresa",
              noResultsText:
                "No fue encontrado ninguna empresa asociada a este usuario",
              searchPlaceholder: "Buscar",
            }}
            data={state.selectCompanies}
          />
        </div>
      </div>

      {state.formType > 1 && (
        <>
          <div className="row">
            <div className="col-6">
              <UploadFile accept=".csv" onChange={_parseCsvFile} />
            </div>
            <div className="col-6 text-end">
              <button
                className="size-09 mt-1"
                type="button"
                style={{
                  border: "2px solid #eee",
                  padding: "5px 12px",
                  background: "#f7f7fa",
                }}
                onClick={downloadCsvBase}
              >
                Download Csv Base
              </button>
            </div>
          </div>

          {errors && errors.length > 0 && (
            <>
              <div className="row mt-3">
                <div className="col-12 bold size-12">Errores</div>
              </div>
              <ErrorsTable data={errors} />
            </>
          )}

          <div className="row mt-4">
            <Modal.Footer>
              <Button onClick={handleClose} appearance="subtle">
                Cerrar
              </Button>
              <Button
                type="button"
                appearance="primary"
                onClick={_handleSubmit}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </div>
        </>
      )}
    </div>
  );
};

export default FormMassiveLoadDeliveryUser;
