/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Toggle } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";

const FormQrRegister = (props: any) => {
  const { type, data, handleClose } = props;
  const [state, setState] = useState<any>({});

  const dispatch: any = useDispatch();
  const Company = new CompanyService();

  useEffect(() => {
    setState({ ...state, ...data });
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    dispatch(loading_on());
    const payload: any = {
      company_id: state.id,
      auto_accept_external_users: state.auto_accept_external_users,
    };

    try {
      const response = await Company.createQrRegister(payload);
      console.log(response.data);
      setState({ ...state, ...response.data?.company });

      if (state.qr_code_image_path) {
        handleClose();
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: `Información actualizada con éxito...`,
        });
        return;
      }
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Qr generado con éxito...`,
      });
      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          {state.qr_code_image_path && (
            <div className="col-12 mb-2 d-flex flex-column">
              <label className="size-08 bold-300 ms-1">Codigo QR</label>
              <img
                src={state.qr_code_image_path}
                alt="qr"
                style={{ width: "250px" }}
              />
            </div>
          )}

          {state.qr_code_plain_text && (
            <div className="col-12 mb-2 d-flex flex-column">
              <label className="size-08 bold-300 ms-1">
                Link para formulario
              </label>
              <a
                href={state.qr_code_plain_text}
                target="_blank"
                rel="noreferrer"
              >
                Accesa al formulario clicando aqui
              </a>
            </div>
          )}

          <div className="col-6 mb-2 d-flex flex-column">
            <label className="size-08 bold-300 ms-1">
              Aprobación automatica
            </label>
            <Toggle
              checked={state.auto_accept_external_users}
              onChange={(value: any) =>
                _handleOnChange({
                  target: { name: "auto_accept_external_users", value },
                })
              }
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              {state.qr_code_image_path ? "Guardar" : "Generar"}
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormQrRegister;
